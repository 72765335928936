/*const contractABI = [
  {
    name: 'isUserRegistered',
    inputs: [{ name: '_account', type: 'address' }],
    outputs: [{ name: "flag", type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },{
    name: 'getContractBalance',
    inputs: [],
    outputs: [{ name: '_balance', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },{
    name: 'getLvlPrices',
    inputs: [],
    outputs: [{ name: '_arr', type: 'uint256[11]' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },{
    name: 'getPlaceInQueue',
    inputs: [{ name: '_account', type: 'address' },{ name: '_lvl', type: 'uint8' }],
    outputs: [{ name: '_placeinq', type: 'uint256' },{ name: '_longq', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },{
    name: 'getReferrerId',
    inputs: [{ name: '_account', type: 'address' }],
    outputs: [{ name: '_id', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },{
    name: 'getUser',
    inputs: [{ name: '_addr', type: 'address' }],
    outputs: [
      { name: '_id', type: 'uint256' },
      { name: '_time', type: 'uint256' },
      { name: '_referals', type: 'uint256' },
      { name: '_refsumpayment', type: 'uint256' },
      { name: '_lvlsumpayment', type: 'uint256' },
      { name: '_lostrefpayment', type: 'uint256' },
      { name: '_addr', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },{
    name: 'getUsersLvls',
    inputs: [{ name: '_addr', type: 'address' }],
    outputs: [
      { name: '_lvl_flags', type: 'bool[]' },
      { name: '_block_hash', type: 'uint16[]' },
      { name: '_max_block_hash', type: 'uint16[]' },
      { name: '_activation', type: 'uint16[]' },
      { name: '_sumpaymentbylvl', type: 'uint256[]' },
      { name: '_sumrefpaymentbylvl', type: 'uint256[]' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },{
    name: 'register',
    inputs: [],
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },{
    name: 'registerWithRef',
    inputs: [{ name: '_addr', type: 'address' }],
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },{
    name: 'regPrice',
    inputs: [],
    outputs: [{ name: '_regPrice', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },{
    name: 'buyLvl',
    inputs: [{ name: '_lvl', type: 'uint8' }],
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },{
    name:"BuyLvl",
    inputs: [{ name:"userId",type:"uint256"},{name:"lvl",type:"uint8"}],
    type:"event",
  },
]*/
const contractABI = [
  {
    "constant": true,
    "inputs": [
      {
        "name": "_addr",
        "type": "address"
      }
    ],
    "name": "isUserRegistered",
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },{
    "name": 'regPrice',
    "inputs": [],
    "outputs": [{ name: '_regPrice', type: 'uint256' }],
    "stateMutability": 'nonpayable',
    "type": 'function',
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_addr",
        "type": "address"
      }
    ],
    "name": "isContract",
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [],
    "name": "register",
    "outputs": [],
    "payable": true,
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "name": "_ref",
        "type": "address"
      }
    ],
    "name": "registerWithRef",
    "outputs": [],
    "payable": true,
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "name": "_lvl",
        "type": "uint8"
      }
    ],
    "name": "buyLvl",
    "outputs": [],
    "payable": true,
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "name": "_userAddress",
        "type": "address"
      },
      {
        "name": "_line",
        "type": "uint8"
      },
      {
        "name": "_lvl",
        "type": "uint8"
      },
      {
        "name": "_rewardValue",
        "type": "uint"
      }
    ],
    "name": "sendRewardToReferrer",
    "outputs": [],
    "payable": false,
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_userAddress",
        "type": "address"
      }
    ],
    "name": "getUser",
    "outputs": [
      {
        "name": "",
        "type": "uint"
      },
      {
        "name": "",
        "type": "uint"
      },
      {
        "name": "",
        "type": "uint"
      },
      {
        "name": "",
        "type": "uint"
      },
      {
        "name": "",
        "type": "uint"
      },
      {
        "name": "",
        "type": "uint"
      },
      {
        "name": "",
        "type": "uint"
      },
      {
        "name": "",
        "type": "address"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_userAddress",
        "type": "address"
      }
    ],
    "name": "getUsersLvls",
    "outputs": [
      {
        "name": "",
        "type": "bool[]"
      },
      {
        "name": "",
        "type": "uint16[]"
      },
      {
        "name": "",
        "type": "uint16[]"
      },
      {
        "name": "",
        "type": "uint16[]"
      },
      {
        "name": "",
        "type": "uint[]"
      },
      {
        "name": "",
        "type": "uint[]"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [],
    "name": "getLvlPrices",
    "outputs": [
      {
        "name": "",
        "type": "uint[11]"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [],
    "name": "getGlobalStatistic",
    "outputs": [
      {
        "name": "",
        "type": "uint[4]"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [],
    "name": "getContractBalance",
    "outputs": [
      {
        "name": "",
        "type": "uint"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_userId",
        "type": "uint"
      }
    ],
    "name": "getUserAddressById",
    "outputs": [
      {
        "name": "",
        "type": "address"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_userAddress",
        "type": "address"
      }
    ],
    "name": "getUserIdByAddress",
    "outputs": [
      {
        "name": "",
        "type": "uint"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_userAddress",
        "type": "address"
      }
    ],
    "name": "getReferrerId",
    "outputs": [
      {
        "name": "",
        "type": "uint"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_userAddress",
        "type": "address"
      }
    ],
    "name": "getReferrer",
    "outputs": [
      {
        "name": "",
        "type": "address"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_userAddress",
        "type": "address"
      },
      {
        "name": "_lvl",
        "type": "uint8"
      }
    ],
    "name": "getPlaceInQueue",
    "outputs": [
      {
        "name": "",
        "type": "uint"
      },
      {
        "name": "",
        "type": "uint"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  }
]

export default contractABI