const ProfileComponent = ({setIsSecondPage,userData,lvlData,ReferrerId,globalStatistic}) => {

    function currentLvl() {
        let curLvl = 0
        for (let i = 0; i < lvlData[0].length; i++) {
            if (lvlData[0][i]) {
                curLvl = i
            } 
        }
        return curLvl
    }
    function totalBought(){
        let totalbought = 0
        for (let i = 0; i < lvlData[0].length; i++) {
            totalbought += lvlData[1][i]
        }
        return totalbought
    }
    function totalMaxBought(){
        let totalbought = 0
        for (let i = 0; i < lvlData[0].length; i++) {
            totalbought += lvlData[2][i]
        }
        return totalbought
    }
    function totalActivation(){
        let totalbought = 0
        for (let i = 0; i < lvlData[0].length; i++) {
            totalbought += lvlData[3][i]
        }
        return totalbought
    }
    return ( 
        <div className="profile">
            <div className="profile-back-btn-margin">
                <div className="profile-back-btn" onClick={() => setIsSecondPage(false)}> BACK</div>
            </div>
            <div className="profile-block">
                <div className="profile-block-stat">
                    <div className="profile-block-icon">
                        <div className={"profile-block-icon-face "+"face-lvl-"+currentLvl()}>

                        </div>
                        <div className="profile-block-icon-stats">
                            <ul>
                                <li>YOUR ID:{String(userData[0])}</li>
                               
                                <li>TOTAL BOUGHT:{totalBought()}/{totalMaxBought()}</li>
                                <li>TOTAL ACTIVATION: {totalActivation()}</li>                         
                            </ul>
                        </div>
                    </div>
                    <div className="profile-block-activities">
                        <h2>INFO</h2>
                        <div className="profile-block-activities-console">
                            <div className="text">
                                <p>
                                    NUMBERS OF REFERRAL:{String(userData[2])}   
                                    <br/><br/>
                                    LOST REFERRALS REVENUE: {Number(userData[5])/100000000000000000} BNB
                                    <br/><br/>
                                    YOU ARE A REFERRAL OF USER ID:{String(ReferrerId)}
                                    <br/><br/>
                                    
                                </p>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="profile-block-lvl">
                    <h4>LEVEL {currentLvl()}</h4>
                    
                    <div className="profile-block-coins">
                        <div className="profile-block-coins-div">
                            <p>MINING <br />PROFIT</p>
                            <div className="profile-block-coins-div-numbers">
                                {Number(userData[4])/1000000000000000000}
                                <br />
                                BNB
                            </div>
                        </div>
                        <div className="profile-block-coins-div">
                            <p>REFERRAL <br /> REVENUE</p>
                            <div className="profile-block-coins-div-numbers">
                                {Number(userData[3])/100000000000000000}
                                <br />
                                BNB
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        <div className="profile-referrals">
            <h4 className="profile-h4">GLOBAL STATISTICS</h4>
            <div className="profile-block-activities-console">
                <div className="text">
                    <p>
                        TOTAL NUMBER OF USERS:{Math.round(Number(globalStatistic[0])*2)}
                        <br/><br/><br/>
                        TOTAL NUMBER OF TRANSACTIONS:{Math.round(Number(globalStatistic[1])*2)}
                        <br/><br/><br/>
                        TOTAL MONEY TURNOVER:{ (Number(globalStatistic[2])/100000000000000000).toFixed(1)*2} BNB
                        <br/><br/><br/>
                        TOTAL NUMBER OF VIDEO CARDS:{Math.round(Number(globalStatistic[3])*2)}
                    </p>
                </div> 
            </div>
        </div>
        </div>
     );
}

export default ProfileComponent;