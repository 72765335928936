import { useState , useEffect } from 'react'
import { useAccount , useContractWrite , useContractReads  } from 'wagmi'
import { readContract } from '@wagmi/core'
import MajorComponent from './MajorComponent'
import ProfileComponent from './ProfileComponent'
import contractABI from "./ABIs"

const ManagerComponent = () => {
    
    const criptoMineContract = {
        address: '0x884F8869CC60Fb758dA5F9D091F22446dF53497f',
        abi: contractABI,
    }
    
    const [lvlData, setLvlData] = useState([[false, false, false, false, false, false, false, false, false, false, false],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n],
        [0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n]]);

    const [lvlPrices, setLvlPrices] = useState([80000000000000000n,110000000000000000n,140000000000000000n,
        200000000000000000n,260000000000000000n,360000000000000000n,480000000000000000n,650000000000000000n,
        870000000000000000n,1120000000000000000n])

    const [userData, setUserData] = useState(0n,0n,0n,0n,0n,0n,"0x01")
    const [registerPrice, setRegisterPrice] = useState(20000000000000000n)
    const [isSecondPage, setIsSecondPage] = useState(false)
    const [activateRegistration, setActivateRegistration] = useState(false)
    const [placeinQueue, setPlaceinQueue] = useState(0n)  
    const [longQueue, setLongQueue] = useState(0n)
    const [ReferrerId, setReferrerId] = useState(0n)
    const [globalStatistic, setGlobalStatistic] = useState([0n,0n,0n,0n])
    const [referralAddress, setReferralAddress] = useState("");
    const [isRefAddressChangeable, setIsRefAddressChangeable] = useState(true);
    const [errorText, setErrorText] = useState("");
    const [errorActive, setErrorActive] = useState(false);
    const [readContractEnable, setReadContractEnable] = useState(false);

    const { address } = useAccount({
        onConnect() {
            getRefAddress()
            isUserRegCheck()
        },
        onDisconnect(){
            setReadContractEnable(false)
            setLvlData([[false, false, false, false, false, false, false, false, false, false, false],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n],
                [0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n]]);
           
            setUserData(0n,0n,0n,0n,0n,0n,"0x01")
            setRegisterPrice(20000000000000000n)
            setActivateRegistration(false)
            setPlaceinQueue(0n)  
            setLongQueue(0n)
            setReferrerId(0n)
           
        }
    })
    useContractReads({
        contracts: [
            {
                ...criptoMineContract,
                functionName: 'getUsersLvls',
                args:[address]
            },
            {
                ...criptoMineContract,
                functionName: 'getUser',
                args:[address]
            },       
            {
                ...criptoMineContract,
                functionName: 'getLvlPrices'
            },
            {
                ...criptoMineContract,
                functionName: 'getReferrerId',
                args:[address]
            },
            {
                ...criptoMineContract,
                functionName: 'getGlobalStatistic'
            },
        ],
        enabled: readContractEnable,
        watch: true,
        onSuccess(data) {
            if (address === undefined) {
                return
            }
            setLvlData(data[0].result)
            setUserData(data[1].result)
            data[2].result.shift()
            setLvlPrices(data[2].result)
            setReferrerId(data[3].result)
            setGlobalStatistic(data[4].result)
            setQueue()
        },
        onError(){
            setErrorActive(true)
            setErrorText("ReadContract")
        }
    })
    const { write } = useContractWrite({
        ...criptoMineContract,
        onSuccess() {
            setActivateRegistration(false)
            setReadContractEnable(true)
        },
        onError(data){
            if(String(data).includes("User rejected the request")){
                setErrorActive(true)
                setErrorText("Transaction")
            }
            else if(String(data).includes("The total cost (gas * gas fee + value)")){
                setErrorActive(true)
                setErrorText("Money")
            }
            else if (String(data).includes("All previous levels must be active")) {
                setErrorActive(true)
                setErrorText("Lvl")
            }
            else if (String(data).includes("Referrer is not registered")) {
                setErrorActive(true)
                setErrorText("RefIsNotReg")
            }
            else if (String(data).includes("User is alredy registered")){
                window.location.reload()
            }
            else if (String(data).includes("Level not available yet")) {
                setErrorActive(true)
                setErrorText("LvlBlock")
            }
            else{
                setErrorActive(true)
                setErrorText("Unknown")
            }
        },
    })

    function getRefAddress(){
        try {
            if (address === undefined) {
                return
            }
            const inputString = window.location.href;
            if (!inputString.includes("ref=")) {
                return
            }
            const parts = inputString.split("ref=");
            const result = parts[1]; 
            const bnbAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
            if (bnbAddressRegex.test(result)) {
                setIsRefAddressChangeable(false)
                setReferralAddress(result)
            }
        } catch (error) {
            console.log(error)
        }
    }
    async function isUserRegCheck(){
        try {
            if (address === undefined) {
                return
            }
            const rdata = await readContract({
                ...criptoMineContract,
                functionName: 'regPrice'
            })
            const isUserReg = await readContract({
                ...criptoMineContract,
                functionName: 'isUserRegistered',
                args:[address]
            })  
            setRegisterPrice(rdata)
            if (isUserReg === false) {
                setActivateRegistration(true)
                return
            }
            if (isUserReg === true) {
                setActivateRegistration(false)
            }
            setReadContractEnable(true)
        } catch (error) {
            console.log(error)
        }
    }
    async function setQueue(){
        try {
            if (address === undefined) {
                return
            }
            let qpdata = [0n]
            let qldata = [0n]
            for (let i = 1; i < 11; i++) {
                const element = await readContract({
                    ...criptoMineContract,
                    functionName: 'getPlaceInQueue',
                    args:[address,i]
                })
                qpdata.push(element[0])
                qldata.push(element[1])
            }
            setPlaceinQueue(qpdata)
            setLongQueue(qldata)
        } catch (error) {
            console.log(error)
        }
    } 
    
    async function userRegistration(referralAddress){
        try {
            if (referralAddress === "") {
                write({functionName:'register',value:registerPrice})
            }
            else{
                write({ functionName:'registerWithRef',args:[referralAddress],value:registerPrice})
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function buyLvl(chosenLvl){
        try {
            write({  functionName: 'buyLvl',args:[chosenLvl],value:lvlPrices[chosenLvl-1]})
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getRefAddress()
        isUserRegCheck()
    }, [address]);

  return (
    <div className="App">
    {  
        isSecondPage ? 
        <ProfileComponent  setIsSecondPage={setIsSecondPage} userData={userData} lvlData={lvlData} ReferrerId={ReferrerId} globalStatistic={globalStatistic}/>
        :
        <MajorComponent setIsSecondPage={setIsSecondPage} buyLvl={buyLvl} userRegistration={userRegistration} setReferralAddress={setReferralAddress}
        setErrorActive={setErrorActive} setErrorText={setErrorText} address={address} lvlData={lvlData} lvlPrices={lvlPrices} 
        activateRegistration={activateRegistration}placeinQueue={placeinQueue} longQueue={longQueue} referralAddress={referralAddress} 
        errorText={errorText} errorActive={errorActive} isRefAddressChangeable={isRefAddressChangeable} globalStatistic={globalStatistic}
        />
    }
    </div>
  );
}

export default ManagerComponent;
