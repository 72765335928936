const coin_index = "BNB"
const lvlBlocked = {
    6:200, 
    7:300,
    8:450,
    9:700,
    10:1200
}

const MajorBarComponent = ({setChosenLvl,lvl,isLvlAvailable,price,totalVideoCards}) => {
    
   
    function getIcon(){
        if (!totalVideoCards) {
            return "shelf-lvl-bar-blocked"
        }
        if (isLvlAvailable) {
            return "shelf-lvl-bar-active"
        }
        else{
            if (totalVideoCards < lvlBlocked[lvl]) {
                return "shelf-lvl-bar-blocked"
            }
            return "shelf-lvl-bar-disabled"
        }
    }

    return ( 
        <div className="major-shelf-lvl" onClick={()=>setChosenLvl(lvl)}>
            <div className="major-shelf-lvl-bar">
                <p>{lvl}</p><p>{price} <br /> {coin_index} </p><span className={"shelf-lvl-bar-icon " + getIcon()}></span> 
            </div>
        </div>
     );
}
 
export default MajorBarComponent;