import MajorBarPrint from "./MajorComponents/MajorBarPrint"
import MajorLvlComponent from "./MajorComponents/MajorLvlComponent"
import RegistrationComponent from "./PopUpComponents/RegistrationComponent"
import ErrorComponent from "./PopUpComponents/ErrorComponent"
import { ConnectButton } from "@rainbow-me/rainbowkit"
import { useState } from 'react'


const MajorComponent = ({setIsSecondPage,buyLvl,userRegistration,setReferralAddress,setErrorActive,setErrorText,
    address,lvlData,lvlPrices,activateRegistration,placeinQueue,longQueue,referralAddress,errorText,errorActive,
    isRefAddressChangeable,globalStatistic}) => {
    
    const [chosenLvl, setChosenLvl] = useState(1);

    const openProfile = () =>{
        if (address) {
            setIsSecondPage(true)
        }
        else{
            setErrorActive(true)
            setErrorText("Disconnected")
        }
    }

    return ( 
    <div className="major">
        <div className="major-menu">
            <div className="major-menu-bar">MENU</div>
            <ConnectButton chainStatus="none"/>
            <div className="major-menu-bar major-menu-bar-profile  bar-btn" onClick={() => openProfile()}>PROFILE</div>
            <a href="https://discord.gg/drB3DRDdPH"><div className="major-menu-bar bar-btn" >DISCORD</div></a>
        </div>
        <div className="major-shelf">
           <MajorBarPrint setChosenLvl={setChosenLvl} priceArr={lvlPrices} isLvlAvailables={lvlData[0]} totalVideoCards={globalStatistic[3]}  />
        </div>
        <div className="major-lvl">
            <MajorLvlComponent lvlData={lvlData} placeinQueue={placeinQueue} 
            longQueue={longQueue} chosenLvl={chosenLvl} buyLvl={buyLvl}/> 
        </div>
        <RegistrationComponent userRegistration={userRegistration} address={address} referralAddress={referralAddress} 
        isRefAddressChangeable={isRefAddressChangeable} setReferralAddress={setReferralAddress} activateRegistration={activateRegistration} />
        <ErrorComponent errorText={errorText} errorActive={errorActive} setErrorActive={setErrorActive}/>
    </div> 
    );
}
 
export default MajorComponent;