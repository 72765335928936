import { useState } from "react";
const RegistrationComponent = ({userRegistration,address,referralAddress,isRefAddressChangeable,setReferralAddress,activateRegistration}) => {

    const [errorAddress, setErrorAddress] = useState(false);
    
    const referralAddressHandler = (e) => {
        setReferralAddress(e.target.value);
        if (errorAddress) {
            const bnbAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
            if (e.target.value === "" || bnbAddressRegex.test(e.target.value)) {
                setErrorAddress(false)
            }
        }
    }
    
    const handleSubmit = () => {
        const bnbAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        if (referralAddress === "") {
            userRegistration(referralAddress)
        }
        else if (bnbAddressRegex.test(referralAddress)) {
            userRegistration(referralAddress)
        }
        else{
            setErrorAddress(true)
        } 
    }

    return ( 
        <div className={activateRegistration ? "wallet-reg-div wallet-reg-active" : "wallet-reg-div" }>
            <div className="wallet-reg-block">
                <div className="wallet-reg-form">
                    <p>{address}</p>
                    {
                        isRefAddressChangeable ?
                        <input type="text" value={referralAddress} className={errorAddress ? "wallet-form-input input-error" : "wallet-form-input"} 
                        placeholder="Referral Address" onChange={referralAddressHandler} />
                        :
                        <input type="text" value={referralAddress} className={"wallet-form-input"} readOnly/>
                    }
                    <div className="wallet-reg-form-submit" onClick={handleSubmit}>REGISTRATION</div>
                </div>
            </div>
        </div>
     );
}
 
export default RegistrationComponent;