import ManagerComponent from './components/ManagerComponent'
import { bscTestnet } from 'wagmi/chains'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { walletConnectProvider } from '@web3modal/wagmi'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { getDefaultWallets, connectorsForWallets, RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit'
import { bitgetWallet,trustWallet,ledgerWallet,coin98Wallet,foxWallet,
  rabbyWallet,enkryptWallet,braveWallet,frontierWallet,zerionWallet } from '@rainbow-me/rainbowkit/wallets'
import '@rainbow-me/rainbowkit/styles.css'
import './css/main.css'
import './css/profile.css'
import './css/popups.css'

global.Buffer = global.Buffer || require("buffer").Buffer

function App() {

  const projectId = '452ad84c8d52622705cd1afaf19ded63'

  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [bscTestnet],
    [infuraProvider({ apiKey: '7c0e6280e9b8407d859fc1d220287e83' }),walletConnectProvider({ projectId }),publicProvider()],
  )
  
  const { wallets } = getDefaultWallets({
    appName: 'crypto-mine',
    projectId: projectId,
    chains,
  })

  const connectors = connectorsForWallets([
    ...wallets,
    {
      groupName: 'Other',
      wallets: [
        trustWallet({ projectId, chains }),
        coin98Wallet({ projectId, chains }),
        ledgerWallet({ projectId, chains }),
        zerionWallet({ projectId, chains }),
        bitgetWallet({ projectId, chains }),
        foxWallet({ projectId, chains }),
        frontierWallet({ projectId, chains }),
        braveWallet({chains}),
        enkryptWallet({chains}),
        rabbyWallet({chains}),
      ],
    },
  ])

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
    webSocketPublicClient,
  })

  return (
    <div className="App">
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider locale="en-US" chains={chains} theme={darkTheme({
            accentColor: '#695f6b',
            accentColorForeground: 'white',
            borderRadius: 'small',
            fontStack: 'system',
            overlayBlur: 'small',
          })}>
          <ManagerComponent/>
        </RainbowKitProvider>
      </WagmiConfig>
    </div>
  );
}

export default App;
