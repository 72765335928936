
const ErrorComponent = ({errorText,errorActive,setErrorActive}) => {

    function setErrorText() {
        const knownError = {
            "Money":"You don't have enough money for the transaction",
            "Lvl":"All previous levels must be active",
            "Transaction":"Transaction is not confirmed",
            "RefIsNotReg":"Referrer is not registered",
            "LvlBlock":"Level not available yet",
            "UserIsReg":"User is alredy registered",
            "Unknown":"Sorry, unknown error, something just went wrong",
            "ReadContract":"Sorry, something just went wrong when we tried to get data from smart contract ",
            "Disconnected":"Sorry, you need to connect your wallet first"
        }
        return knownError[errorText]
    }

    return ( 
        <div className={errorActive ? "error-div error-div-active" : "error-div" } onClick={ () => setErrorActive(false) } >
            <div className="error-block">
                <p>{setErrorText()}</p>
            </div>
        </div>
    );
}
 
export default ErrorComponent;