
const MajorLvlComponent = ({lvlData,placeinQueue,longQueue,chosenLvl,buyLvl}) =>{

    function getPlaceInQueue(){
        if (!lvlData) {
            return "0%"
        }
        const place = 100 - (100/Number(longQueue[chosenLvl]) * Number(placeinQueue[chosenLvl]))
        if (place) {
            return String(place.toFixed(1))+"%"
        }
        else{
            return "0%"
        }
    }
    return(
        <div className="major-block-lvl">
            <h4>LEVEL {chosenLvl}</h4> 
            <p>{lvlData ? lvlData[0][chosenLvl] ? "UNBLOCKED" : "BLOCKED" : "BLOCKED"}</p>   
            <div className="profile-block-hash">
                <p>BLOCK HASH</p>
                <p>{lvlData ? lvlData[1][chosenLvl]+"/"+lvlData[2][chosenLvl] : "0%"}</p>
            </div>
            <div className="profile-block-repair">
                <p>{getPlaceInQueue()}</p>
            </div>
            <div className="profile-block-coins">
                <div className="profile-block-coins-div">
                    <p>MINING <br />PROFIT</p>
                    <div className="profile-block-coins-div-numbers">
                        {lvlData ? Number(lvlData[4][chosenLvl])/1000000000000000000 : "0"}
                        <br />
                        BNB
                    </div>
                </div>
                <div className="profile-block-coins-div">
                    <p>REFERRAL <br /> REVENUE</p>
                    <div className="profile-block-coins-div-numbers">
                        {lvlData ? Number(lvlData[5][chosenLvl])/100000000000000000 : "0"}
                        <br />
                        BNB
                    </div>
                </div>
            </div>
            {lvlData ?
            <div className="major-block-lvl-buy-btn" onClick={() => buyLvl(chosenLvl)}>
                BUY
            </div>
            : 
            <div className="major-block-lvl-buy-btn">
                BLOCKED
            </div> 
            }
        </div>
    )
}
export default MajorLvlComponent